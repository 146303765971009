<svg fill="none" height="30" viewBox="0 0 234 36" width="234" xmlns="http://www.w3.org/2000/svg"
	><g fill="#000"
		><path
			d="m22.4839 2.98345c0-1.152-.912-2.159996-2.304-2.159996h-17.03996c-1.392 0-2.304002 1.055996-2.304002 2.159996s.816002 2.112 2.352002 2.112h6.144v27.50405c0 1.728.95996 2.688 2.35196 2.688 1.44 0 2.304-.96 2.304-2.688v-27.50405h6.192c1.488 0 2.304-1.008 2.304-2.112z"
		/><path
			d="m48.9856 33.3675c0-.384-.144-.96-.384-1.584l-10.272-28.36805c-.528-1.488-1.488-2.975997-3.504-2.975997-1.92 0-2.928 1.535997-3.456 2.975997l-10.272 28.46405c-.192.528-.336 1.104-.336 1.488 0 1.056.864 1.92 2.112 1.92 1.488 0 2.016-.816 2.4-1.92l2.448-7.008h14.256l2.448 7.056c.384 1.056.96 1.872 2.448 1.872 1.248 0 2.112-.864 2.112-1.92zm-8.448-11.328h-11.376l5.664-16.12805h.096z"
		/><path
			d="m68.7589 2.98345c0-1.152-.912-2.159996-2.304-2.159996h-17.04c-1.392 0-2.304 1.055996-2.304 2.159996s.816 2.112 2.352 2.112h6.144v27.50405c0 1.728.96 2.688 2.352 2.688 1.44 0 2.304-.96 2.304-2.688v-27.50405h6.192c1.488 0 2.304-1.008 2.304-2.112z"
		/><path
			d="m89.6551 32.8395c0-1.152-.864-2.112-2.352-2.112h-9.984v-10.704h9.024c1.536 0 2.4-1.008 2.4-2.112 0-1.152-.864-2.16-2.4-2.16h-8.976v-10.65605h9.456c1.488 0 2.352-1.008 2.352-2.112 0-1.152-.864-2.159996-2.352-2.159996h-11.52c-1.872 0-2.64 1.007996-2.64 2.591996v29.18405c0 1.536.912 2.4 2.64 2.4h12c1.488 0 2.352-1.056 2.352-2.16z"
		/></g
	><path
		d="m134.928 18.1995c0-8.83205-4.752-17.376046-15.264-17.376046h-8.352c-1.776 0-2.64.911996-2.64 2.447996v29.32805c0 1.728 1.152 2.4 2.688 2.4h8.256c8.832 0 15.312-6.384 15.312-16.8zm-4.944-.48c0 7.248-2.88 13.008-10.512 13.008h-6.144v-25.63205h6.096c7.68 0 10.56 6.24005 10.56 12.62405z"
		fill="#0072ce"
	/><path
		d="m162.461 33.2715c0-.432-.144-.96-.576-1.632l-7.68-11.424c5.04-.816 7.824-5.04 7.824-9.648 0-5.61605-3.936-9.744046-10.848-9.744046h-7.872c-1.872 0-2.64.911996-2.64 2.447996v29.32805c0 1.728.912 2.688 2.352 2.688 1.392 0 2.304-.96 2.304-2.688v-12.192h3.744l9.024 13.728c.432.624 1.008 1.2 2.064 1.2s2.304-.768 2.304-2.064zm-5.232-22.224c0 3.552-2.304 5.616-6.24 5.616h-5.664v-11.56805h5.712c3.984 0 6.192 2.016 6.192 5.95205z"
		fill="#0072ce"
	/><path
		d="m194.279 33.3675c0-.384-.144-.96-.384-1.584l-10.272-28.36805c-.528-1.488-1.488-2.975997-3.504-2.975997-1.92 0-2.928 1.535997-3.456 2.975997l-10.272 28.46405c-.192.528-.336 1.104-.336 1.488 0 1.056.864 1.92 2.112 1.92 1.488 0 2.016-.816 2.4-1.92l2.448-7.008h14.256l2.448 7.056c.384 1.056.96 1.872 2.448 1.872 1.248 0 2.112-.864 2.112-1.92zm-8.448-11.328h-11.376l5.664-16.12805h.096z"
		fill="#0072ce"
	/><path
		d="m233.93 2.55145c0-1.152-.864-2.015998-2.16-2.015998s-2.256.671998-2.544 2.303998l-4.416 26.20805h-.096l-7.584-26.49605c-.336-1.104-1.104-2.015998-2.448-2.015998-1.392 0-2.112.911998-2.448 2.015998l-7.584 26.49605h-.096l-4.464-26.20805c-.288-1.68-1.2-2.303998-2.496-2.303998s-2.208.863998-2.208 2.015998c0 .48.192 1.296.48 2.928l5.232 27.31205c.288 1.536 1.68 2.496 3.264 2.496 1.632 0 2.88-.864 3.312-2.448l6.96-24.52805h.096l6.864 24.57605c.432 1.584 1.68 2.4 3.264 2.4 1.632 0 2.976-.96 3.264-2.496l5.376-27.31205c.288-1.392.432-2.448.432-2.928z"
		fill="#0072ce"
	/></svg
>
